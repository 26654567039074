import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/app.css'
import { Routes, Route, BrowserRouter, Navigate, Link } from 'react-router-dom'
import { GlobalContextProvider } from './store/GlobalContextProvider';
import { Upload } from './scenes/upload/Upload';
import { Preview } from './scenes/preview/Preview';
import { Result } from './scenes/result/Result';
import { Clipping } from './scenes/clipping/Clipping';
import { CustomRoute } from './components/CustomRoute';
import { NotificationsPanel } from './components/NotificationsPanel';
import { NewResult } from './scenes/result/NewResult';

function App() {
  return (
    <GlobalContextProvider >
      <BrowserRouter>
        {/* <div className="d-flex justify-content-center header">
          <Link to="/" className='link'><h1>ISHP</h1></Link>
        </div> */}
        <NotificationsPanel />
        <Routes>
          <Route path="/" element={<Navigate to="/upload" />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/preview" element={<CustomRoute><Preview /> </CustomRoute>} />
          <Route path="/result" element={<CustomRoute><Result /> </CustomRoute>} />
          <Route path="/result/debug" element={<CustomRoute><Result isDebug={true}/> </CustomRoute>} />
          <Route path="/new-result" element={<CustomRoute><NewResult isDebug={true}/> </CustomRoute>} />
          <Route path="/clipping" element={<CustomRoute><Clipping /> </CustomRoute>} />
        </Routes>
      </BrowserRouter>
    </GlobalContextProvider>
  );
}

export default App;
