import { faArrowLeft, faArrowRotateBackward, faBug, faImage, faScissors, faShare, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { resetImage, setImage, setImageDetail } from '../../store/actions/ImageActions'
import { addNotification } from '../../store/actions/NotificationsActions'
import { GlobalContext } from '../../store/GlobalContextProvider'
import Select from 'react-select'


export const Preview = () => {

    const navigate = useNavigate()
    const { imageState, imageDispatch, notificationsDispatch } = React.useContext(GlobalContext)
    const handleClick = (isDebug: boolean, path: string) => {
        // addNotification(notificationsDispatch, "Success", "Image has successsul sended to server to processing", "success", 1000)

        fetch(`${process.env.REACT_APP_API}/api/image/${path}${isDebug ? '/debug' : ''}`, {
            method: "POST",
            // mode: "no-cors",
            headers: {
                'Content-Type': 'application/json'
                // 'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({ image: imageState.image })
        }).then((res) => {
            if (res.ok) {
                return res.json()
            }
            throw new Error("Error: " + res.statusText)
        }).then(json => {
            setImageDetail(imageDispatch, json)
        }).catch((err: Error) => {
            addNotification(notificationsDispatch, "Error", err.message, "danger")
            navigate("/preview")
        })
    }

    return (
        <Container fluid className='p-0'>
            <h2 className='ms-3'>{<FontAwesomeIcon icon={faImage} className="me-1" />}Preview</h2>
            {/* <hr /> */}
            {!imageState.image ? <div className='d-flex justify-content-center'> <ClipLoader loading={true} /></div>
                : <div className='border rounded p-3'>
                    <div className="d-flex justify-content-around mb-1 flex-column align-items-stretch flex-sm-row">

                        <Link to="/upload" className='d-flex align-items-stretch flex-column mb-1'>
                            <Button variant="outline-dark" onClick={() => { resetImage(imageDispatch) }}>
                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                            </Button>
                        </Link>
                        <Button variant='outline-warning' className='mb-1'
                            onClick={() => { setImage(imageDispatch, imageState.origin!) }}
                        >
                            <FontAwesomeIcon icon={faArrowRotateBackward} /> Reset
                        </Button>
                        <Link to="/clipping" className='d-flex align-items-stretch flex-column mb-1'>
                            <Button variant="outline-primary"><FontAwesomeIcon icon={faScissors} /> Cut out</Button>
                        </Link>
                        {/* <Link to="/result/debug" className='d-flex align-items-stretch flex-column mb-1'>
                            <Button variant="outline-success" onClick={() => { handleClick(true, "upload") }}>
                                <FontAwesomeIcon icon={faBug} /> Send to debug
                            </Button>
                        </Link> */}
                        <Link to="/new-result" className='d-flex align-items-stretch flex-column mb-1'>
                            <Button variant="outline-success" onClick={() => { handleClick(true, "new-upload") }}>
                                <FontAwesomeIcon icon={faWandSparkles} /> Send to new model
                            </Button>
                        </Link>
                        {/*<Link to="/result" className='d-flex align-items-stretch flex-column mb-1'>
                            <Button variant="outline-success" onClick={() => { handleClick(false) }}>
                                <FontAwesomeIcon icon={faShare} /> Send
                            </Button>
                        </Link>*/}
                    </div>

                    <div className="d-flex justify-content-center">
                        <img src={imageState.image} width="100%" alt="" className='mx-auto' />
                    </div>
                </div>}
        </Container>

    )
}
